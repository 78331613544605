export default function (id, arrematante, lote = null) {
  let window = typeof id !== 'undefined' ? `arrematante.arquivo.${id}.show` : 'arrematante.arquivo.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Novo Arquivo',
    move: false,
    backdrop: true,
    clickOutside: false,
    closeOnEsc: false,
    windowClass: 'erp-window-modern v2 no-border-radius',
    width: '800',
    height: '780',
    props: {
      id: id,
      $router: this.$router,
      arrematante: arrematante,
      lote: lote
    }
  }, () => import('./Arquivo'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        updated: (wid, val) => {
          console.log('Update event')
          this.load && this.load()
          this.entityUpdate && this.entityUpdate(val)
        },
        created: (wid, val) => {
          console.log('Create event')
          this.load && this.load()
          this.entityUpdate && this.entityUpdate(val)
        }
      })
    }) // return wid
}
