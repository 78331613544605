import http from '../../../utils/services/http'

export const list = (limit, page, filtros, basic) => {
  let url = '/api/arrematanteAnalisesPendentes?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/arrematanteAnalises/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newAnalise = (data) => {
  let url = `/api/arrematanteAnalises`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateAnalise = (id, data) => {
  let url = `/api/arrematanteAnalises/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteAnalise = (id) => {
  let url = `/api/arrematanteAnalises/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const iniciarAnaliseCadastral = (data) => {
  let url = `/api/arrematanteAnalises`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const aprovarCadastroArrematante = (arrematanteId) => {
  let url = `/api/arrematanteAnalises/aprovar-cadastro/${arrematanteId}`
  return http.put(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const requisitarAnalise = (id) => {
  let url = `/api/arrematanteAnalises/${id}/mover-para-outro-usuario`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export default {
  list,
  find
}
