<template>
  <div>
    <p class="font-11">Por meio de uma procuração, o <strong>outorgante</strong> passa ao <strong>outorgado</strong> a permissão de representá-lo em participação de leilões com lances, pagamento e retirada.</p>

    <representantes-arrematante label="Arrematantes que esta pessoa representa:" :arrematante="arrematante" />
    <div class="text-right m-t-sm"><e-btn>Incluir novo <strong style="margin-left: 3px">representante</strong></e-btn></div>
    <representantes-arrematante label="Procurações enviadas por esta pessoa para ser representada:" representados :arrematante="arrematante" />
    <div class="text-right m-t-sm"><e-btn>Incluir nova <strong style="margin-left: 3px">procuração</strong></e-btn></div>
  </div>
</template>

<script>
import RepresentantesArrematante from '../../../pda/components/apps/arrematante/Representantes';
export default {
  name: 'Representantes',
  components: {RepresentantesArrematante},
  props: ['arrematante']
}
</script>

<style lang="stylus">

</style>
