<template>
  <div>
    <div class="row">
      <div v-for="arquivo in arrematante.documentos" class="arquivo hoverable">
        <div class="font-14 font-bold">
          {{arquivo.nome}}
        </div>
        <div v-if="arquivo.obrigatorio" class="m-t-xs">
          <i class="file-required bg-negative text-white">Obrigatório</i>
        </div>
        <div class="m-t-lg text-center_">
          <btn-status-documento-arrematante :arrematante="arrematante" :documento="arquivo" />
        </div>
        <div class="m-t options flex justify-between hidden-nohover">
          <u-btn :loading="arquivo.downloading" icon="eye" @click="download(arquivo)" icon-type="fa" flat
                 label="visualizar" size="sm" class="w-full" />
          <u-btn icon="times" color="negative" @click="removerDocumento(arquivo)" icon-type="fa" flat label="excluir"
                 size="sm" class="w-full" />
        </div>
      </div>

      <div v-for="arquivo in documentosRequeridosPendentes" class="arquivo hoverable">
        <div class="font-14 font-bold">
          {{arquivo.nome}}
        </div>
        <div v-if="arquivo.obrigatorio" class="m-t-xs">
          <i class="file-required bg-negative text-white">Obrigatório</i>
        </div>
        <div class="m-t-lg text-center_">
          <u-btn disable icon="exclamation-triangle" icon-type="fa" label="Não enviado" color="grey-3"
                 class="text-black" no-caps size="sm" />
        </div>
        <div class="m-t options flex justify-between hidden-nohover">
          <u-btn size="sm" icon="upload" color="primary" icon-type="fa" flat rounded round title="Fazer upload"
                 class="fileContainer">
            <input
                @change="uploadDocumento(arquivo.id, $event)" :ref="'fileDocumento' + arquivo.id" type="file" />
          </u-btn>
          <u-btn size="sm" icon="envelope" color="blue-grey-4" icon-type="fa" flat rounded label="Solicitar"
                 @click="solicitar(arquivo)" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {StatusDocumento} from '../../../../domain/arrematante/helpers/Status'
import BtnStatusDocumentoArrematante from './BtnStatusDocumento'
import fileType from '../../../../mixins/fileType'
import {deleteDocumento, downloadDocumento, uploadDocumento as upload} from '../../../../domain/arrematante/services'
import {fileToBase64} from '../../../../utils/fileToBase64'

export default {
  name: 'ArrematanteDocumentos',
  mixins: [fileType],
  components: {BtnStatusDocumentoArrematante},
  props: ['arrematante'],
  data () {
    return {}
  },
  computed: {
    documentosRequeridosPendentes () {
      let documentosEnviados = this.arrematante.documentos.map((el) => {
        return el.tipo.id
      }).filter(function (item, pos, self) {
        return self.indexOf(item) === pos
      })
      return this.arrematante.documentosRequeridos.filter(function (item) {
        return documentosEnviados.indexOf(item.id) === -1
      })
    },
    isApto () {
      let apto = true
      // Verifica se existe algum documento obrigatório sem enviar
      this.documentosRequeridosPendentes.forEach((item) => {
        if (item.obrigatorio) {
          apto = false
        }
      })
      // Verifica se existe algum documento não aprovado
      this.arrematante.documentos.forEach((item) => {
        if (item.status !== 2) {
          apto = false
        }
      })
      return apto
    }
  },
  methods: {
    getFileIco (file) {
      let path = file.originalFilename.split('.')
      let extension = path.pop()
      return this.bindIcon(extension)
    },
    DocumentoStatus (status) {
      if (typeof StatusDocumento[status] !== 'undefined') {
        return StatusDocumento[status]
      }
      return {title: 'Status Inválido', class: ''}
    },
    removerDocumento (arquivo) {
      this.$uloc.dialog({
        title: 'Você está prestes a remover um documento',
        message: 'Você tem certeza que deseja remover este documento do cadastro do arrematante? Esta operação não pode ser desfeita.',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        console.log('Removendo...', arquivo)
        deleteDocumento(this.arrematante.id, arquivo.id)
          .then(response => {
            console.log(response.data)
            this.arrematante.documentos.splice(this.arrematante.documentos.indexOf(arquivo), 1)
            this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Documento excluído com sucesso.'})
          })
          .catch((error) => {
            console.log(error)
            this.alertApiError(error)
          })
      }).catch(() => {
      })
    },
    uploadDocumento (docid, e) {
      console.log('upload', docid, e)
      this.$uloc.loading.show()
      fileToBase64(e.target.files[0])
        .then(_file => {
          upload(this.arrematante.id, {
            data: _file,
            tipo: docid,
            filename: e.target.files[0].name
          })
            .then(response => {
              console.log(response.data)
              this.$uloc.loading.hide()
              if (!this.arrematante.documentos || !Array.isArray(this.arrematante.documentos)) {
                this.arrematante.documentos = []
              }
              this.arrematante.documentos.push(response.data)
            })
            .catch(error => {
              this.alertApiError(error)
              this.$uloc.loading.hide()
            })
        })
        .catch((error => {
          this.$uloc.loading.hide()
          this.$uloc.dialog({
            title: 'Falha ao tentar enviar o arquivo',
            message: `Tente novamente ou comunique o administrador do sistema ?`,
            noCaps: true,
            ok: `Ok`
          })
        }))
    },
    solicitar (arquivo) {
      this.$uloc.dialog({
        title: 'Solicitar ao arrematante envio de documento',
        message: `Tem certeza que deseja solicitar ao arrematante o envio do documento ${arquivo.nome} ?`,
        noCaps: true,
        ok: `Solicitar`,
        cancel: 'Cancelar'
      })
        .then(() => {
          this.$uloc.notify({
            color: 'positive',
            message: 'Solicitação enviada pelos meios de comunicação disponíveis para o arrematante.'
          })
        })
        .catch(() => {

        })
    },
    download (arquivo) {
      downloadDocumento(this.arrematante.id, arquivo.id)
        .then(response => {
          /*const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          // link.setAttribute('download', 'file.pdf'); //or any other extension
          document.body.appendChild(link)
          link.click();
          document.body.removeChild(link)*/
          arquivo.downloading = true
          console.log(response.data)
          const link = document.createElement('a')
          link.href = response.data.url
          link.target = '_blank'
          document.body.appendChild(link)
          link.click();
          window.setTimeout(() => {
            document.body.removeChild(link)
            arquivo.downloading = false
          }, 100)
          // window.open(response.data.url)
        })
        .catch(error => {
          arquivo.downloading = false
          this.alertApiError(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
