export const STATUS_AGUARDANDO_ENVIO = 0;
export const STATUS_AGUARDANDO_VERIFICACAO = 1;
export const STATUS_VERIFICADO = 2;
export const STATUS_ILEGIVEL = 3;
export const STATUS_INCORRETO = 4;
export const STATUS_FRAUDE= 5;

export const STATUS = {
  [STATUS_AGUARDANDO_ENVIO]: 'Aguardando o envio dos arquivos',
  [STATUS_AGUARDANDO_VERIFICACAO]: 'Em análise',
  [STATUS_VERIFICADO]: 'Verificado',
  [STATUS_ILEGIVEL]: 'Ilegível',
  [STATUS_INCORRETO]: 'Incorreto',
  [STATUS_FRAUDE]: 'Fraude'
}
