import http from '../../../utils/services/http'

export const list = (id, limit, page, filtros) => {
  let url = '/api/arrematantes/' + id + '/arquivos?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id, arquivo) => {
  let url = '/api/arrematantes/' + id + '/arquivos/' + arquivo
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newArquivo = (id, arquivo) => {
  let url = '/api/arrematantes/' + id + '/arquivos'
  return http.post(url, arquivo)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const update = (id, arquivo) => {
  let url = '/api/arrematantes/' + id + '/arquivos/' + arquivo.id
  return http.patch(url, arquivo)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteArquivo = (id, arquivo) => {
  let url = '/api/arrematantes/' + id + '/arquivos/' + arquivo
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const downloadArquivo = (arrematante, arquivo) => {
  let url = `/api/arrematantes/${arrematante}/arquivos/${arquivo}/download`
  return http.get(url/*, {
    responseType: 'blob',
    timeout: 30000
  }*/)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}
