<template>
  <div class="full-width">

    <div class="m-t">
      <!--      <sl-table :columns="listStorage" :loading="loading">
              <template v-if="listData && listData.length" v-slot:body>
                <sl-table-tr v-for="data in listData" :key="data.id">
                  <sl-table-td>{{data.nome}}</sl-table-td>
                  <sl-table-td>{{data.inicio|formatDate('dd/MM/yyyy')}}</sl-table-td>
                  <sl-table-td>{{data.fim|formatDate('dd/MM/yyyy')}}</sl-table-td>
                  <sl-table-td>{{data.motivo}}</sl-table-td>
                </sl-table-tr>
              </template>
              <template v-else v-slot:body>
                <tr>
                  <td colspan="100%">Nenhum dado cadastrado.</td>
                </tr>
              </template>
            </sl-table>-->
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="sl-table no-scroll m-t-md"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <!--          <u-th auto-width>
                      <u-checkbox
                          v-model="props.selected"
                          :indeterminate="props.partialSelected"
                          size="sm"
                      />
                    </u-th>-->
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-right">
              <span class="cursor-pointer" @click="listOptions">Opções <i class="fal fa-cog"></i></span>
            </div>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row.id)" slot="body" slot-scope="props"
              :props="props" :class="{'u-table-item-destac': props.row.isToday}">
          <!--          <u-td auto-width>
                      <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                    </u-td>-->
          <u-td key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td key="dateCreated" :props="props">
            {{ props.row.createdAt|formatDate }}
          </u-td>
          <u-td key="nome" :props="props">
            {{ props.row.nome }}
          </u-td>
          <u-td key="tipo" :props="props">
            {{ props.row.tipo ? props.row.tipo.nome : '-' }}
          </u-td>
          <u-td key="leilao" :props="props">
            <div v-if="props.row.lote">
              <a class="text-black" @click="leilaoWindow(props.row.lote.leilao.id)">#{{props.row.lote.leilao.id}}</a> - <a @click="loteWindow(props.row.lote)">{{props.row.lote.numeroString || props.row.lote.numero}}</a>
            </div>
            <div v-else>-</div>
          </u-td>
          <u-td key="validade" :props="props">
            <div v-if="props.row.validade">{{ props.row.validade|formatDate('dd/MM/yyyy') }}</div>
            <div v-else>-</div>
          </u-td>
          <u-td key="dataVisualizacao" :props="props">
            <div v-if="props.row.dataVisualizacao">{{ props.row.dataVisualizacao|formatDate('dd/MM/yyyy HH:mm') }}</div>
            <div v-else>-</div>
          </u-td>
          <u-td key="active" :props="props">
            <div class="text-positive" v-if="props.row.active">Sim</div>
            <div class="text-negative" v-else>Não</div>
          </u-td>
          <u-td key="options" class="text-right" :props="props">
            <u-btn @click="download(props.row)" icon="eye" icon-type="fa" size="xs" rounded round flat color="primary" />
            <e-btn-table-options>
              <menu-options>
                <list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                           @excluir="excluir(props.row.id)" :leilao="props.row"/>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>
      <h2 class="diviser"></h2>
      <div v-if="arrematante.id && !loading" class="m-t">
        <u-btn @click="novo" class="sl-btn" color="positive" no-caps label="Registrar arquivo"/>
      </div>
    </div>
  </div>
</template>

<script>
import ArrematanteMixin from "@/components/arrematante/components/include/ArrematanteMixin"
import {list, deleteArquivo, downloadArquivo} from "@/domain/arrematante/services/arquivos"
import {listStorage} from "@/components/arrematante/components/include/arquivos/config/list"
import arquivoWindow from "@/components/arrematante/components/include/arquivos/window/arquivoWindow"
import ListMixin from "@/reuse/list/ListMixin"
import leilaoWindow from "components/leiloes/windows/leilao";
import loteWindow from "components/leiloes/windows/lote";

export default {
  name: "ArquivosArrematante",
  props: {
    lote: {required: false}
  },
  data() {
    return {
      listStorage: this.listStorage,
      loading: false,
      listData: null
    }
  },
  components: {
    // SlTableTd,
    // SlTableTr,
    // SlTable
  },
  mounted() {
    this.arrematante.id && this.load()
  },
  mixins: [ArrematanteMixin, ListMixin],
  watch: {},
  beforeCreate() {
    this.listStorage = listStorage
  },
  computed: {},
  methods: {
    /*load () {
      this.loading = true
      list(this.bem.id)
      .then(response => {
        this.loading = false
        this.listData = response.data.result
        console.log(response.data)
      })
      .catch(error => {
        this.loading = false
        this.alertApiError(error)
      })
    },*/
    request({pagination, filter}) {
      console.log('Loading')

      this.table.loading = true
      const extraFilters = []
      this.lote && extraFilters.push('lote=' + this.lote.id)
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}&${extraFilters.join('&')}`)

      list(this.arrematante.id, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    arquivoWindow,
    novo() {
      this.arquivoWindow(null, this.arrematante, this.lote)
    },
    abrir(id) {
      this.arquivoWindow(id, this.arrematante, this.lote)
    },
    excluir(id) {
      this.$uloc.dialog({
        title: 'Excluir registro',
        message: 'Você tem certeza que deseja excluir este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Deletando...'})
        deleteArquivo(this.arrematante.id, id)
            .then(() => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    },
    download (arquivo) {
      downloadArquivo(this.arrematante.id, arquivo.id)
          .then(response => {
            /*const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            // link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link)
            link.click();
            document.body.removeChild(link)*/
            arquivo.downloading = true
            console.log(response.data)
            const link = document.createElement('a')
            link.href = response.data.url
            link.target = '_blank'
            document.body.appendChild(link)
            link.click();
            window.setTimeout(() => {
              document.body.removeChild(link)
              arquivo.downloading = false
            }, 100)
            // window.open(response.data.url)
          })
          .catch(error => {
            arquivo.downloading = false
            this.alertApiError(error)
          })
    },
    leilaoWindow: leilaoWindow,
    /*leilaoWindow (id) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'leiloes.show', params: {id: id}})
      window.open(routeData.href, '_blank')
    },*/
    loteWindow: loteWindow
  }
}
</script>
