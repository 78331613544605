<template>
  <window-content v-if="!loading && !errors">
    <div>
      <buttons-arrematante-window />
    </div>
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 190px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input  v-model="arrematante.id" disable />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 180px">
          <tipo-arrematante-select label="Tipo:" field-view="lr" v-model="arrematante.pessoa.type" />
        </e-col>

        <e-col style="max-width: 340px">
          <document-input :pessoa="arrematante.pessoa" @mergePessoa="(p) => arrematante.pessoa = p" @data="(d) => processData(d)" />
        </e-col>

        <e-col style="max-width: 260px">
          <status-select label="Status:" placeholder="Selecione" field-view="lr"
                         v-model="arrematante.status" />
        </e-col>

      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              :label="arrematante.pessoa.type === 1 ? 'Nome completo:' : 'Razão social:'"
              label-width="110px"
          >
            <erp-input v-model="arrematante.pessoa.name" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 230px">
          <erp-s-field
              view="lr"
              label="Apelido:"
              label-width="50px"
          >
            <apelido-input :simple-border="false" :nome="arrematante.pessoa.name" :gerar-blur="!arrematante.id"
                           v-model="arrematante.apelido" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="110px"
          >
            <erp-input v-model="arrematante.pessoa.emails[0].email" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Celular:"
              label-width="50px"
          >
            <erp-input v-model="arrematante.pessoa.phoneNumbers[0].phoneNumber"
                       v-mask="'(##) # ####-####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Residencial:"
              label-width="70px"
          >
            <erp-input v-model="arrematante.pessoa.phoneNumbers[1].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Comercial:"
              label-width="70px"
          >
            <erp-input v-model="arrematante.pessoa.phoneNumbers[2].phoneNumber"
                       v-mask="'(##) ####-#####'" />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
      <erp-tab-item v-if="arrematante.id" name="documentacao" :class="{'arrematante-documentos-tab': tabActive === 'documentacao'}">Análise/Documentação</erp-tab-item>
<!--      <erp-tab-item v-if="isEmpresa" name="socios">Sócios</erp-tab-item>-->
      <erp-tab-item name="contato">Endereço e Contato</erp-tab-item>
      <erp-tab-item v-if="arrematante.id" name="leiloes">Leiloes e Arremates</erp-tab-item>
      <erp-tab-item v-if="arrematante.id" name="arquivos">Arquivos</erp-tab-item>
      <erp-tab-item v-if="arrematante.id" name="logs">Logs</erp-tab-item>
<!--      <erp-tab-item name="representantes">Representantes</erp-tab-item>
      <erp-tab-item name="financeiro">Financeiro</erp-tab-item>-->
    </erp-tabs>
    <window-body class="font-11" :class="{'flex col-grow': tabActive === 'logs' || tabActive === 'leiloes', 'arrematante-documentos': tabActive === 'documentacao'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row mr>
              <e-col style="min-width: 280px">
                <erp-s-field
                    view="lr"
                    :label="isPessoaFisica ? 'RG:' : 'Inscrição Estadual'"
                    label-width="120px"
                >
                  <erp-input v-model="arrematante.pessoa.documents[0].identifier" />
                </erp-s-field>
              </e-col>

              <e-col v-if="isPessoaFisica">
                <erp-s-field
                    view="lr"
                    label="Emissor:"
                >
                  <erp-input v-model="arrematante.pessoa.documents[0].agentDispatcher" />
                </erp-s-field>
              </e-col>

              <e-col v-if="isPessoaFisica">
                <erp-s-field
                    view="lr"
                    label="Expedição:"
                >
                  <erp-input v-model="arrematante.pessoa.documents[0].expedition" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isPessoaFisica" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nascimento:"
                    label-width="120px"
                >
                  <erp-input v-model="arrematante.pessoa.birthDate" v-mask="'##/##/####'" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nacionalidade:"
                >
                  <erp-input v-model="arrematante.pessoa.nationality" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Sexo:"
                >
                  <erp-select placeholder="Selecione"
                              :options="[{label: 'Masculino', value: 1},{label: 'Feminino', value: 2}]"
                              v-model="arrematante.pessoa.gender" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row mr>
              <e-col style="max-width: 260px">
                <erp-s-field
                    view="lr"
                    :label="isPessoaFisica ? 'Estado Civil:' : 'Estado Civil (Sócio):'"
                    label-width="120px"
                >
                  <erp-select placeholder="Selecione" :options="EstadoCivil" v-model="arrematante.estadoCivil" />
                </erp-s-field>
              </e-col>
              <e-col v-if="isCasado">
                <erp-s-field
                    view="lr"
                    label="Regime de Casamento:"
                >
                  <erp-select placeholder="Selecione"
                              :options="RegimesCasamento"
                              v-model="arrematante.regimeCasamento" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isCasado" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Nome Cônjuge:"
                    label-width="120px"
                >
                  <erp-input v-model="arrematante.conjugeNome" />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row v-if="isCasado" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="CPF Cônjuge:"
                    label-width="120px"
                >
                  <erp-input v-model="arrematante.conjugeCpf" v-mask="'###.###.###-##'" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="RG Cônjuge:"
                >
                  <erp-input v-model="arrematante.conjugeRg" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isPessoaFisica" mr>
              <e-col style="max-width: 260px">
                <erp-s-field
                    view="lr"
                    label="Profissão:"
                    label-width="120px"
                >
                  <erp-input v-model="arrematante.profissao" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row v-if="isEmpresa" mr>
              <e-col style="max-width: 360px; min-width: 360px;">
                <erp-s-field
                    view="lr"
                    label="Proprietário:"
                    label-width="120px"
                >
                  <erp-input v-model="arrematante.pjProprietarioNome" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="CPF:"
                >
                  <erp-input v-model="arrematante.pjProprietarioCpf" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="RG:"
                >
                  <erp-input v-model="arrematante.pjProprietarioRg" />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row v-if="isEmpresa" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Dt. Nasc. (Sócio):"
                    label-width="120px"
                >
                  <erp-input v-model="arrematante.pessoa.birthDate" style="max-width: 100px" v-mask="'##/##/####'" />
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row class="m-t-xs" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="120px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="arrematante.lanceAutomatico">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Habilitar lances automáticos</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row class="" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="120px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="arrematante.podeComprarSucata">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Habilitar compra de sucatas</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>
            <!--<e-row class="" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="120px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="arrematante.permitirCompraBlindado">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Habilitar compra de blindados</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>-->
            <e-row class="m-t-xs" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="120px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="arrematante.newsletter">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Inscrito para newsletter</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Descrição interna:"
                    class="wrap label-wrap"
                    label-width="120px"
                >
                  <textarea class="w-full erp-input wrapper-xs" rows="4"
                            v-model="arrematante.observacao"></textarea>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
          <e-col v-if="!resultadoApi" class="m-l-xxl" style="min-width: 216px; max-width: 216px">
            <foto ref="foto" :arrematante="arrematante" @update="updateFoto" @toDelete="deleteFotoEvent" />
            <div class="text-center m-t-xs">
              <score :arrematante="arrematante" />
            </div>
          </e-col>
          <e-col class="bg-black text-white" style="max-width: 40%; min-width: 40%; overflow: auto; font-size: 12px; margin-left: 20px; max-height: 400px" v-if="resultadoApi">
              <div class="erp-window-macos-code bg-black text-white">
                <pre class="font-12 wrapper-sm">{{JSON.stringify(resultadoApi, null, 1)}}</pre>
              </div>
          </e-col>
        </e-row>
        <e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm">Acesso</div>
            <acesso :arrematante="arrematante" />
          </e-col>
        </e-row>
        <!--<e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm font-11">Após o cadastro ou alteração:</div>
            <e-row>
              <e-col>
                <erp-checkbox class="flex no-wrap items-center m-r" v-model="arrematante.enviarBoasVindas">
                  <label class="no-select text-nowrap font-bold">
                    <span class="m-l-xs text-nowrap">Enviar e-mail de boas vindas</span>
                  </label>
                </erp-checkbox>

                <erp-checkbox class="flex no-wrap items-center m-r" v-model="arrematante.solicitarDocumentacao">
                  <label class="no-select text-nowrap font-bold">
                    <span class="m-l-xs text-nowrap">Solicitar documentação</span>
                  </label>
                </erp-checkbox>

                <erp-checkbox class="flex no-wrap items-center m-r" v-model="arrematante.solicitarCriacaoSenha">
                  <label class="no-select text-nowrap font-bold">
                    <span class="m-l-xs text-nowrap">Solicitar criação de senha</span>
                  </label>
                </erp-checkbox>
              </e-col>
            </e-row>
          </e-col>
        </e-row>-->
      </div>
      <contato v-show="tabActive === 'contato'" class="wrapper m-t" :entidade="arrematante" />
      <div v-if="tabActive === 'documentacao' && arrematante.id" class="wrapper m-t w-full">
        <analise-cadastral :arrematante="arrematante" @alterarStatus="setArrematanteStatusCadastro" />
        <arrematante-documentos :arrematante="arrematante" />
      </div>
      <socios v-if="tabActive === 'socios' && arrematante.id" class="wrapper m-t w-full">
        ...
      </socios>
      <div v-if="tabActive === 'logs'" class="wrapper flex col-grow">
        <log :usuario="arrematante" type="arrematante" />
      </div>
      <div v-if="tabActive === 'leiloes'" class="wrapper m-t flex col-grow">
        <pendencias label="Histórico de arremates" :arrematante="arrematante" class="w-full" mostrar-tudo />
        <div class="full-width m-t">
          <p class="font-bold font-11 m-b-sm">Lances</p>
          <historico-lances :arrematante="arrematante" />

          <p class="font-bold font-11 m-t-lg m-b-sm">Participação em leilões</p>
          <historico-leiloes :arrematante="arrematante" />
        </div>
      </div>
      <div v-if="tabActive === 'arquivos'" class="wrapper flex col-grow">
        <arquivos-arrematante :arrematante="arrematante"  />
      </div>
      <div v-if="tabActive === 'representantes'" class="wrapper m-t">
        <representantes :arrematante="arrematante" />
      </div>
      <div v-if="tabActive === 'financeiro'" class="wrapper m-t">
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  // WindowHeader,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {date} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {
  find,
  newArrematante,
  updateArrematante,
  removePersonObject, updateFotoArrematante
} from '../../../../domain/arrematante/services/index'
import DatetimeInput from '../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import StatusSelect from '../include/StatusArrematanteSelect'
import PatioInput from '../../../patio/helpers/input/PatioInput'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import TipoArrematanteSelect from '../include/TipoArrematanteSelect'
import ApelidoInput from '../include/ApelidoInput'
import {EstadoCivil, ESTADO_CIVIL_CASADO, ESTADO_CIVIL_UNIAO_ESTAVEL, RegimesCasamento} from '@/domain/arrematante/helpers/Status'
import Score from '../../../pda/components/apps/arrematante/Score'
import Acesso from '../include/Acesso'
import Log from '../../../usuario/components/include/Log'
import HistoricoLances from '../include/HistoricoLances'
import HistoricoLeiloes from '../include/HistoricoLeiloes'
import Pendencias from '../../../pda/components/apps/arrematante/Pendencias'
import Representantes from '../include/Representantes'
import {adjustPurposes} from '@/domain/pessoa/helpers/Purpose'
import {TIPO_PESSOA_JURIDICA} from '@/domain/pessoa/helpers/Status'
import Foto from '../include/Foto'
// import Endereco from '../../../pessoa/components/include/Endereco'
// import EnderecoV2 from '../../../pessoa/components/include/EnderecoV2'
import Contato from '../../../pessoa/components/include/Contato'

import {mockEndereco} from '../../../pessoa/mock/endereco'
import {mockTelefone} from '../../../pessoa/mock/telefone'
import {mockEmail} from '../../../pessoa/mock/email'
import {mockContato} from '../../../pessoa/mock/contato'
import {mockDocument} from '../../../pessoa/mock/document'
import ArrematanteDocumentos from '../include/Documentos'
import AnaliseCadastral from '../aprovacao/AnaliseCadastral'
import ButtonsArrematanteWindow from 'components/arrematante/components/include/buttons-arrematante-window'
import DocumentInput from "components/pessoa/components/include/DocumentInput"
import ArquivosArrematante from "components/arrematante/components/include/Arquivos"
import Socios from "components/arrematante/components/include/Socios"

let mockArrematante = {
  id: null,
  apelido: null,
  conjugeNome: null,
  conjugeCpf: null,
  conjugeRg: null,
  lanceAutomatico: true,
  podeComprarSucata: false,
  newsletter: true,
  score: null,
  estadoCivil: null,
  pjProprietarioCpf: null,
  pjProprietarioNome: null,
  pjProprietarioRg: null,
  pjProprietarioTelefoneComercial: null,
  observacao: null,
  pessoa: {
    id: null,
    name: null,
    type: 1,
    document: null,
    birthDate: null,
    nationality: null,
    gender: null,
    addresses: [JSON.parse(JSON.stringify(mockEndereco))],
    phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone)), JSON.parse(JSON.stringify(mockTelefone))],
    emails: [JSON.parse(JSON.stringify(mockEmail))],
    contacts: [JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato)), JSON.parse(JSON.stringify(mockContato))],
    documents: [JSON.parse(JSON.stringify(mockDocument))]
  },
  enviarBoasVindas: true,
  solicitarDocumentacao: true,
  solicitarCriacaoSenha: true,
  profissao: null,
  regimeCasamento: null,
  status: 0,
  /*extra: {
    socioEndereco: null,
    socioUf: null,
    socioCidade: null,
    socioCep: null,
    socioEmail: null,
    socioTelefone: null,
    socios: []
  }*/
}

export default {
  name: 'ArrematanteWindow',
  provide: function () {
    return {
      arrematanteContainer: this
    }
  },
  directives: {money: VMoney},
  props: ['id'],
  data () {
    return {
      money: REAL_BRL,
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      deleteFoto: false,
      arrematante: JSON.parse(JSON.stringify(mockArrematante)),
      resultadoApi: null
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    EstadoCivil () {
      return EstadoCivil
    },
    RegimesCasamento () {
      return RegimesCasamento
    },
    EstadoCivilCasado () {
      return ESTADO_CIVIL_CASADO
    },
    EstadoCivilUniaoEstavel () {
      return ESTADO_CIVIL_UNIAO_ESTAVEL
    },
    isPessoaFisica () {
      return this.arrematante.pessoa.type === 1
    },
    isEmpresa () {
      return this.arrematante.pessoa.type === 2
    },
    isCasado () {
      return this.arrematante.estadoCivil === ESTADO_CIVIL_CASADO || this.arrematante.estadoCivil === ESTADO_CIVIL_UNIAO_ESTAVEL
    }
  },
  watch: {},
  methods: {
    load () {
      (this.id || this.arrematante.id) && find(this.id || this.arrematante.id)
        .then(response => {
          let data = response.data
          if (data.pessoa.birthDate && data.pessoa.birthDate.date) {
            data.pessoa.birthDate = date.formatDate(data.pessoa.birthDate.date, 'DD/MM/YYYY')
          }
          // let mock = JSON.parse(JSON.stringify(mockArrematante))
          // console.log(mock)
          // this.arrematante = Object.assign(mock, data)
          // console.log(this.arrematante)
          if (!data.pessoa.addresses || data.pessoa.addresses.length === 0) {
            data.pessoa.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
          }

          if (!data.pessoa.phoneNumbers || data.pessoa.phoneNumbers.length < 3) {
            if (!Array.isArray(data.pessoa.phoneNumbers)) {
              data.pessoa.phoneNumbers = []
            }
            const len = 3 - Number(data.pessoa.phoneNumbers.length)
            for (let i = 0; i < len; i++) {
              data.pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
            }
          }

          if (!data.pessoa.emails || data.pessoa.emails.length === 0) {
            data.pessoa.emails = [JSON.parse(JSON.stringify(mockEmail))]
          }

          if (!data.pessoa.documents || data.pessoa.documents.length === 0) {
            data.pessoa.documents = [JSON.parse(JSON.stringify(mockDocument))]
          }

          adjustPurposes([
            data.pessoa.addresses,
            data.pessoa.phoneNumbers,
            data.pessoa.contacts,
            data.pessoa.emails
          ])

          /*if (!data.extra || Array.isArray(data.extra)) {
            data.extra = JSON.parse(JSON.stringify(mockArrematante.extra))
          }*/

          this.arrematante = data
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o arrematante, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.arrematante))

      // Ajusta dados
      let phones = []
      this.arrematante.pessoa.phoneNumbers.forEach((phone, index) => {
        if (phone.phoneNumber && String(phone.phoneNumber).length > 4) {
          phones.push(phone)
        }
      })
      data.pessoa.phoneNumbers = phones

      let contacts = []
      this.arrematante.pessoa.contacts.forEach((contact, index) => {
        if (contact.value && String(contact.value).length > 2) {
          contacts.push(contacts)
        }
      })
      data.pessoa.contacts = contacts

      data.pessoa.addresses.map((address) => {
        if (address.cityId && address.cityId.id) {
          address.cityId = address.cityId.id
        }
        if (address.city && address.city.label) {
          address.city = address.city.label
        }
      })

      data.pessoa.documents && data.pessoa.documents.map((document) => {
        if (document.type && document.type.id) {
          document.type = document.type.id
        }
      })

      if (data.pessoa.type === TIPO_PESSOA_JURIDICA) {
        // data.pessoa.documents = null
      }

      /*data.extra = JSON.stringify(data.extra)*/

      this.status = 'Processando dados do arrematante'
      let method = data.id ? updateArrematante(data.id, data) : newArrematante(data)
      method
        .then(({data}) => {
          this.arrematante.id = data.id
          const cbSuccess = () => {
            this.success = true
            this.load()
            this.dg()
            this.$nextTick(() => {
              // this.$refs.timer.start()
              /* window.setTimeout(() => {
                this.loading = false
                this.success = false
                this.load()
              }, 3000) */
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
              // this.$refs.timer.start()
            })
          }
          // Processa foto
          if (this.deleteFoto) {
            console.log('Deleta Foto')
          } else if (this.$fotoArrematante) {
            this.status = 'Analisando e processando foto do arrematante'
            updateFotoArrematante(this.arrematante.id, {data: this.$fotoArrematante, filename: 'foto'})
              .then(({data}) => {
                cbSuccess()
                delete this.$fotoArrematante
              })
              .catch((error) => {
                alert('Impossível enviar a foto do arrematante, mas os demais dados foram processados com sucesso!')
                console.error(error)
                cbSuccess()
              })
          } else {
            cbSuccess()
          }

        })
        .catch(error => {
          console.log(error)
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    deleteFotoEvent () {
      this.deleteFoto = true
    },
    updateFoto (file) {
      this.$fotoArrematante = file
    },
    setArrematanteStatusCadastro (status) {
      this.arrematante.status = status
    },
    processData (d) {
      if (d.responsavel) {
        this.arrematante.pjProprietarioNome = d.responsavel
      }
      if (d.responsavelCpf) {
        this.arrematante.pjProprietarioCpf = d.responsavelCpf
      }
    }
  },
  components: {
    Socios,
    ArquivosArrematante,
    DocumentInput,
    ButtonsArrematanteWindow,
    AnaliseCadastral,
    ArrematanteDocumentos,
    Contato,
    // Endereco,
    // EnderecoV2,
    Foto,
    Representantes,
    Pendencias,
    HistoricoLeiloes,
    HistoricoLances,
    Log,
    Acesso,
    Score,
    ApelidoInput,
    TipoArrematanteSelect,
    ECol,
    ERow,
    PatioInput,
    StatusSelect,
    DefaultAsyncSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    // WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    ErpLabel,
    // UEditor,
    // URadio
    ErpSelect,
    DatetimeInput,
    ErpCheckbox
  }
}
</script>
