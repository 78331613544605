<template>
  <div>
    <div class="flex col-grow justify-between" style="height: 160px !important;">
      <div class="col-grow flex justify-between h-full">
        <e-window-table fixed class="col-grow h-full"
                        :columns="['Data', 'Valor', 'Leilão', 'Lote', 'Status', '']"
        >
          <e-tr v-for="(data, index) in lances" :key="index">
            <e-td>{{ data.data.date|formatDate('dd/MM/yyyy HH:mm:ss') }}</e-td>
            <e-td class="text-nowrap">R$ {{ data.valor | moeda }}</e-td>
            <e-td><a>{{data.leilao.codigo}}</a></e-td>
            <e-td class="text-nowrap l-130">{{ data.lote.numero }} - {{ data.lote.descricao }}</e-td>
            <e-td>
              <lance-registro-status :lance="data" />
            </e-td>
            <e-td><a @click="open(data.id)"><i class="erp-icon search min"></i> </a></e-td>
          </e-tr>
          <e-tr v-if="!lances || !lances.length">
            <e-td style="text-align: left !important;" colspan="100%">
              Nenhum registro
            </e-td>
          </e-tr>
        </e-window-table>
      </div>
    </div>
    <e-table-pagination class="m-t-xs" v-model="page" :max="max" />
  </div>
</template>

<script>
import {ETd, ETr, EWindowTable, ETablePagination} from 'uloc-vue-plugin-erp'
import {historicoLances} from '../../../../domain/arrematante/services'
import LanceRegistroStatus from '../../../leiloes/components/include/lote/LanceRegistroStatus'

export default {
  name: 'HistoricoLances',
  props: {
    arrematante: {
      required: true
    }
  },
  components: {
    LanceRegistroStatus,
    EWindowTable,
    ETr,
    ETd,
    ETablePagination
  },
  data () {
    return {
      lances: null,
      loading: false,
      page: 1,
      max: 1
    }
  },
  mounted () {
    this.load()
  },
  computed: {},
  methods: {
    load () {
      this.loading = true
      historicoLances(this.arrematante.id, 50, 1, '')
        .then(({data}) => {
          if (!Array.isArray(data.result)) {
            return
          }
          this.max = data.total
          this.lances = data.result
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    open (id) {
      console.log('open...')
    }
  }
}
</script>
