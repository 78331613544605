import {ErpInput, ErpSField,} from 'uloc-vue-plugin-erp'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
export default {
  inject: ['arrematanteContainer'],
  components: {
    ErpSField,
    ErpInput,
    ERow,
    ECol
  },
  props: {
  },
  computed: {
    arrematante () {
      return this.arrematanteContainer.arrematante
    },
    router () {
      return this.arrematanteContainer.router
    }
  }
}
