<script>
import {UPopover} from 'uloc-vue'
import {StatusDocumento as Status, getStatus} from '../../../../domain/arrematante/helpers/Status'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import {updateStatusDocumento} from '../../../../domain/arrematante/services'

export default {
  name: 'BtnStatusDocumentoArrematante',
  props: ['documento', 'arrematante'],
  data () {
    return {
      loading: false
    }
  },
  components: {MenuOptionsItem, UPopover},
  computed: {
    status () {
      return Object.keys(Status).map((k) => {
        return {
          label: Status[k].title,
          value: Number(k),
          class: typeof Status[k].title !== 'undefined' ? Status[k].class : null
        }
      })
    },
    statusFiltered () {
      return this.status.filter((s) => s.value !== Number(this.documento.status))
    },
    bindIcon () {
      return this.getStatus(this.documento.status).icon
    }
  },
  methods: {
    getStatus (s) {
      return getStatus(s, Status)
    },
    updateStatus (newStatus) {
      this.$uloc.dialog({
        title: 'Alterar status do documento',
        message: `Tem certeza que deseja alterar o status de ${this.getStatus(this.documento.status).title} para ${this.getStatus(newStatus).title} ?`,
        noCaps: true,
        ok: `Confirmar novo status: ${this.getStatus(newStatus).title}`,
        cancel: 'Não'
      })
        .then(() => {
          // this.$emit('updateStatus', newStatus)
          updateStatusDocumento(this.arrematante.id, this.documento.id, {
            id: this.documento.id,
            status: newStatus
          })
            .then(response => {
              console.log(response.data)
              this.documento.status = newStatus
            })
            .catch(error => {
              this.alertApiError(error)
            })
        })
        .catch(() => {

        })

    }
  }
}
</script>

<template>
  <e-btn :icon="bindIcon" icon-type="fa" :loading="loading" class="w-full text-left arrematante-documento-status scolor" align="left"
         :class="getStatus(documento.status).class || ''" :label="getStatus(documento.status).title">
    <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm" style="font-size: 10px !important;" />
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu menu-btn-arrematante-documento">
        <ul>
          <menu-options-item v-for="(s, i) in statusFiltered" close :key="i" class="arrematante-documento-status scolor"
                             :class="s.class" @click="updateStatus(s.value)">
          <span class="menuLabel">
                <div class="flex col-grow no-wrap justify-between">
                  <div class="flex items-center">
                    <u-icon name="square" type="fa" class="m-r-xs s-icon" />
                    <span class="menu-label-content">{{s.label}}</span>
                  </div>
                </div>
            </span>
          </menu-options-item>
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
