<script>
/* eslint-disable */
import {UWindowHeaderExtButtons, UTooltip, event, UPopover} from 'uloc-vue'
import MenuOptionsItem from 'components/layout/context-menu/context-window-options-item'
import http from "@/utils/services/http";
import {consultarCpfCnpjj} from "@/domain/pessoa/services"

export default {
  inject: ['arrematanteContainer'],
  name: 'buttons-arrematante-window',
  mixins: [UWindowHeaderExtButtons],
  data() {
    return {}
  },
  computed: {
    arrematante() {
      return this.arrematanteContainer.arrematante
    }
  },
  methods: {
    consultaCpf() {
      consultarCpfCnpj(this.arrematante.pessoa.document.replace(/\D/g, ''))
          .then(response => {
            console.log(response.data)
            this.arrematanteContainer.resultadoApi = response.data
            this.$uloc.loading.hide()
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    consultaCnpj() {
      this.$uloc.loading.show({message: 'Consultando API...'})
      return new Promise((resolve, reject) => {
        http.get(`https://brasilapi.com.br/api/cnpj/v1/${this.arrematante.pessoa.document.replace(/\D/g, '')}`, {
          transformRequest(data, headers) {
            delete headers.common['Authorization']
            return data
          }
        })
            .then(response => {
              console.log(response.data)
              this.arrematanteContainer.resultadoApi = response.data
              this.$uloc.loading.hide()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      })
    }
  },
  components: {UTooltip, MenuOptionsItem, UPopover}
}
</script>

<template>
  <div class="ext-btns inline m-r">
    <!-- @TODO: Email de boas vindas e outras comunicações -->
<!--    <a class="m-l">
      <u-icon name="bars" type="fa"/>
      <u-tooltip :offset="[4,4]">Opções</u-tooltip>
      <u-popover class="window-context-menu no-border-radius" anchor="bottom right" self="top right">
        <div class="menu_ menu-btn-leilao">
          <ul>
            <menu-options-item label="Consultar CPF" close @click="consultaCpf"/>
          </ul>
          <ul v-if="arrematante.pessoa.type === 2">
            <menu-options-item label="Consultar CNPJ" close @click="consultaCnpj"/>
          </ul>
          <slot/>
        </div>
      </u-popover>
    </a>-->
  </div>
</template>
