<template>
  <div>
    <collapse :collapse="true" title="Sócio Principal">
      <e-row mr>
        <e-col style="max-width: 360px; min-width: 360px;">
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="120px"
          >
            <erp-input v-model="arrematante.pjProprietarioNome" />
          </erp-s-field>
        </e-col>

        <e-col>
          <erp-s-field
              view="lr"
              label="CPF:"
          >
            <erp-input v-model="arrematante.pjProprietarioCpf" />
          </erp-s-field>
        </e-col>

        <e-col>
          <erp-s-field
              view="lr"
              label="RG:"
          >
            <erp-input v-model="arrematante.pjProprietarioRg" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="min-width: 350px">
          <erp-s-field
              view="lr"
              label="Endereço:"
              label-width="120px"
          >
            <erp-input v-model="arrematante.extra.socioEndereco" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Cidade:"
          >
            <erp-input v-model="arrematante.extra.socioCidade" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 100px">
          <erp-s-field
              view="lr"
              label="UF:"
          >
            <erp-input v-model="arrematante.extra.socioUf" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="CEP:"
          >
            <erp-input v-model="arrematante.extra.socioCep" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col>
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="120px"
          >
            <erp-input v-model="arrematante.extra.socioEmail" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field
              view="lr"
              label="Telefone:"
          >
            <erp-input v-model="arrematante.extra.socioTelefone" />
          </erp-s-field>
        </e-col>
      </e-row>
    </collapse>
    <collapse :collapse="true" title="Demais Sócios">
      <div v-for="socio in arrematante.extra.socios" class="m-b">
        <e-row mr>
          <e-col style="max-width: 360px; min-width: 360px;">
            <erp-s-field
                view="lr"
                label="Nome:"
                label-width="120px"
            >
              <erp-input v-model="socio.nome" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="CPF:"
            >
              <erp-input v-model="socio.cpf" />
            </erp-s-field>
          </e-col>

          <e-col>
            <erp-s-field
                view="lr"
                label="RG:"
            >
              <erp-input v-model="socio.rg" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col style="min-width: 350px">
            <erp-s-field
                view="lr"
                label="Endereço:"
                label-width="120px"
            >
              <erp-input v-model="socio.endereco" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                view="lr"
                label="Cidade:"
            >
              <erp-input v-model="socio.cidade" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 100px">
            <erp-s-field
                view="lr"
                label="UF:"
            >
              <erp-input v-model="socio.uf" />
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 150px">
            <erp-s-field
                view="lr"
                label="CEP:"
            >
              <erp-input v-model="socio.cep" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                view="lr"
                label="E-mail:"
                label-width="120px"
            >
              <erp-input v-model="socio.email" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                view="lr"
                label="Telefone:"
            >
              <erp-input v-model="socio.telefone" />
            </erp-s-field>
          </e-col>
        </e-row>
        <a @click="remover(socio)" class="text-negative">Remover sócio</a>
      </div>
      <e-btn @click="adicionar" label="Adicionar sócio" />
    </collapse>
  </div>
</template>

<script>
import Collapse from "components/globalconfig/components/include/Collapse"
import ECol from "components/layout/components/Col"
import ERow from "components/layout/components/Row"
import {ErpInput, ErpSField} from "@/plugins/uloc-erp"

const mockSocio = {
  nome: null,
  cpf: null,
  rg: null,
  email: null,
  telefone: null,
  endereco: null,
  cidade: null,
  uf: null,
  cep: null
}

export default {
  name: "Socios",
  inject: ['arrematanteContainer'],
  computed: {
    arrematante () {
      return this.arrematanteContainer.arrematante
    }
  },
  methods: {
    adicionar () {
      this.arrematante.extra.socios.push(JSON.parse(JSON.stringify(mockSocio)))
    },
    remover (s) {
      this.arrematante.extra.socios.splice(this.arrematante.extra.socios.indexOf(s), 1)
    }
  },
  components: {
    Collapse,
    ECol,
    ERow,
    ErpSField,
    ErpInput,
  }
}
</script>
