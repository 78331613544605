import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 2
export const listName = 'arrematante.arquivos.list'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: false, ordering: 1},
    {label: 'Criado em', name: 'dateCreated', active: false, sortable: false, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: false, ordering: 1},
    {label: 'Tipo', name: 'tipo', active: true, sortable: false, ordering: 2},
    {label: 'Leilão/Lote', name: 'leilao', active: true, sortable: false, ordering: 3},
    {label: 'Validade', name: 'validade', active: true, sortable: false, ordering: 4},
    {label: 'Visualizado', name: 'dataVisualizacao', active: true, sortable: false, ordering: 5},
    {label: 'Disponível', name: 'active', active: true, sortable: false, ordering: 6}
], listVersion)
